import { useEffect, useState } from "react";
import ColorLogo from '../assets/color-logo.png';
import { ToastContainer, toast } from 'react-toastify';
import Aos from "aos";
import 'aos/dist/aos.css';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [description, setDescription] = useState();

    useEffect(() => {
        Aos.init({ duration: 1000 });
    });

    const submit = async (e) => {
        e.preventDefault();

        let data = new FormData();
        data.append('name', name);
        data.append('email', email);
        data.append('message', description);

        await axios
            .post("https://api.veepmeep.com/api/add/to/maillist", data)
            .then((response) => {
                if (response.data.status) {
                    toast.success('🎉 Stay Connected!', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {

                    toast.warn(response.data.message, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

                setName('');
                setEmail('');
                setDescription('');
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }

    return (
        <div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className='bg-main' >
                <div class="flex flex-row w-full">
                    <div class='flex xl:w-[10%] w-0'></div>
                    <div class="flex flex-col xl:w-[80%] w-full left-0 right-0 items-center text-center justify-center my-10 mt-12 md:mt-28">
                        <img src={ColorLogo} alt='Logo' className="md:w-1/4 w-1/2" />
                        <div className="flex flex-col mt-8 md:mt-16 md:w-[72%] w-[90%] font-SFPro md:text-5xl text-2xl text-left" data-aos='fade-up'>
                            <p className="text-white leading-snug">
                                veep meep is a <span className='font-SFPro-Bold'>community</span> that enables you to
                                <span className='font-SFPro-Bold'> discover and connect</span> with <span className='font-SFPro-Bold'>like-minded people,</span> <br />
                                or <span className='font-SFPro-Bold'>get a date</span> (not a fruit silly :-).
                                <br /><br />
                                you can also find products, services, and opportunities.
                                <br /><br />
                                see <span className='font-SFPro-Bold'>who is available,</span> see <span className='font-SFPro-Bold'>what is available</span>
                                <br /><br />
                                don’t just chat or match… <span className='font-SFPro-Bold'>veep.</span>
                            </p>
                        </div>
                        <div className="flex flex-col mt-12 md:w-[72%] w-[90%] font-SFPro md:text-[34px] text-lg text-left leading-tight text-white" data-aos='fade-up'>
                            Whether you want to discover people, and get a date,<br />
                            or showcase your products or services; simply,<br />
                            <span className='font-SFPro-Bold'>join our mailing list to be notified when veep meep will be available</span>
                            on the Google Play Store, Apple App Store and Huawei App Gallery
                        </div>
                        <form className="flex flex-col mt-20 mb-20 md:w-[72%] w-[90%] font-SFPro items-center" onSubmit={submit}>
                            <input type='text' required placeholder="Name" value={name} onChange={(e) => { setName(e.target.value) }} className='w-full h-16 text-[16px] md:text-[32px] px-8 block p-4 text-[#616161] rounded-full sm:text-md focus:ring-blue-700 border-0' data-aos='fade-up' />
                            <input type='email' required placeholder="Email" value={email} onChange={(e) => { setEmail(e.target.value) }} className='mt-8 w-full h-16 text-[16px] md:text-[32px] px-8 block p-4 text-[#616161] rounded-full sm:text-md focus:ring-blue-700' data-aos='fade-up' />
                            <textarea type='text' required value={description} onChange={(e) => { setDescription(e.target.value) }} className='mt-8 w-full h-48 text-[16px] md:text-[32px] px-8 block p-4 text-[#616161] rounded-3xl sm:text-md focus:ring-blue-700' placeholder='If you want to, tell us a bit about what you would like to see on the app? What would make the app a great experience for you?' data-aos='fade-up' />

                            <button type="submit" className='tracking-[0px] mt-16 text-[18px] font-SFPro-Bold md:text-[32px] bg-[#E5F411] w-[90%] md:w-2/3 xl:md:w-2/5 p-3 rounded-full text-[#0F76AF] items-center' data-aos='fade-up'>stay connected</button>

                        </form>

                        <div className="flex md:justify-between md:flex-row flex-col justify-center w-[80%] xl:w-full  border-t-2 text-white">
                            <div>
                                © 2022 VEEP MEEP. ALL RIGHTS RESERVED
                            </div>
                            <div className="py-4 md:py-0">
                                <a href="/privacy-policy">Terms & Privacy Policy</a>
                            </div>
                        </div>
                    </div>
                    <div class='flex xl:w-[10%] w-0'></div>
                </div>
            </div>
        </div>
    );
}
export default Home;