import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Sidebar from "./components/Sidebar";

function App() {

  return (
    <div>
      <Sidebar />
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="*" element={<Home />}></Route>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />}></Route>
      </Routes>
    </div>
  );
}

export default App;