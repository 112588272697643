import React, { useEffect, useState } from 'react'
import Menu from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';


function Sidebar() {

    const [isOpen, setOpen] = useState(false);
    const [path, setPath] = useState(null);;

    useEffect(() => {
        setPath(window.location.pathname);
    },[]);

    return (
        <>
            {!isOpen ?
                (
                    <Menu fontSize='large' className={`ml-5 mt-5 cursor-pointer fixed ${path !== '/' ? 'text-black': 'text-white'}`} onClick={() => setOpen(!isOpen)} />
                )
                :
                (
                    <div>

                        <Close fontSize='large' className={`mt-4 ml-4 fixed cursor-pointer z-50 ${path !== '/' ? 'text-white': 'text-black'}`} onClick={() => { setOpen(!isOpen) }} />

                    </div>
                )

            }

            <div className={`top-0 left-0 fixed ${path !== '/' ? 'bg-main': 'bg-white'} md:w-[50vw] xl:w-[25vw] lg:w-[40vw] h-full p-10 ${isOpen ? 'translate-x-0' : '-translate-x-full'} ease-in-out duration-300 z-40`}>

                <div className={`ml-5 text-[18px] mt-4 ${path !== '/' ? 'text-white': 'text-black'}`}>
                    <div>
                        <div className=''>
                            <a href='/' onClick={() => { setOpen(!isOpen) }}>Home</a>
                        </div>

                        <hr className={`divide-y-8 border-solid  h-[1px] ${path !== '/' ? 'bg-white border-white': 'bg-black border-black mt-5'}`}></hr>

                        <div className='pt-[20px]'>
                            <a href="/privacy-policy">Terms & Privacy Policy</a>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Sidebar;
